import React,{useRef,useState,useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { IoSearchSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import UploadFile from '../Components/UploadFile';
import {v4 as uuidv4} from 'uuid';
import { TokenGetter, GetUserInfo,getCookie,setCookie,getEmail,setCookieToken,apiTokenGetter } from "../Endpoints/LoginActions";
import UploadContractImage from '../Img/UploadContractsImg.png';
import AccessResultsOffshore from '../Img/AcessOffshoreResults.png';
import bubbleImg from '../Img/bubbleImg.png';
import axios from 'axios';
import useLoader from "../Hook/useLoader";
import SnackBar from '../Components/SnackBar';



 
const SearchPage=()=>{
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const searchText = useRef(null);
  const [uniqueId, setUniqueId]=useState(null);
  const [loader, showLoader, hideLoader] = useLoader();
  const authCookieToken=getCookie('authToken');
  const [contracts,setContracts]=useState('');
  const [clients,setClients]=useState('');
  const alertRef=useRef();
  const [show,setShow]=useState(false);


  

  
const apiCallDummy=async()=>{
  try {
    showLoader();

    
    const header = {
      headers: {
          "Content-Type": "application/json",
      }
  }
  
      const apiToken = await apiTokenGetter();
      const email = getEmail();
    const input_json={
      "token":apiToken,
      "user_id":email
    };

    const response= await axios.post(process.env.REACT_APP_API_URI+"processed_contracts", input_json, header);


    // const response = await axios.post(
    //   process.env.REACT_APP_API_URI+"processed_contracts"
    // );
    console.log("response of processedContracts api is",response);

   
    // console.log("response",response);
    const distinctContracts=new Set(response.data.Results.map(item=>item.Agreement_ID)).size;

    const distinctClients=new Set(response.data.Results.map(item=>item.Payer_Name)).size;
    if(response.status===200){

    setContracts(distinctContracts);
    setClients(distinctClients);
    setShow(true);
    hideLoader();
  }
  } catch (error) {
    alertRef.current.showSnackbar();
    hideLoader() 

  }
}


  useEffect(()=>{
    
    const token = apiTokenGetter();
    token.then((value)=>{
        console.log("token from auth api Searchpage useEffect is...",value);
        setCookieToken('apiToken',value,30)
        apiCallDummy();
    });
    
   

    
    
  },[]);
  const handleSearch = async () => {
    if (searchText.current.value !== "") {
      try {
        window.open(`/search/${searchText.current.value}`);
      }
      catch (error) {
      }
    }
    else { }
  }
  const handleKeyDown = (e) => {
    try{
    if (e.key === 'Enter') {
      handleSearch();
    }

  }
  catch(error)
    {
      // AppInsight.trackException({error});
      // AppInsight.trackEvent({name:'error-handleKeyDown-Home=>frontend',properties:{errorMessage:error.message}});
      // setShowSnackbar(true);
    }
  }
  const handleClickOpen=()=>{
    setUniqueId(uuidv4());
    setOpen(true);
  }
  const handleClose=()=>{
    setOpen(false);
  } 
  return (
    <div className="Search-Home">
          <div className="HeadingApp1">
            <br></br>
           <div className='SearchEngine '>
              <h4>Search Engine</h4>
            </div>
            <div className='SearchBelowContent '>
            <h6>A feature to perform generic search on top of LHC Contracts</h6>
            </div>
            <input type="text" ref={searchText} onKeyDown={(e) => handleKeyDown(e)}  placeholder='search'  className='SearchInput' />
            <i className='SearchIcon'  ><IoSearchSharp onClick={() => handleSearch()}/></i>
          
          </div>
          <img src={bubbleImg} alt={bubbleImg} style={{width:'25%',marginTop:'-11%',marginLeft:'70%'}}/>
          <div className='Text21k'>
                {contracts}
                <div className='TextContracts'>
                 {show===true?'Contracts':''}   
                </div>
          </div>
          <div className='Text3839'>
                {clients}
                <div className='TextClients'>
                {show===true?'Clients':''}   
                </div>
          </div>
          <br></br>

          <div  className='TextOffshoreRestrictionProcessing'>
           {show===true?"Offshore Restriction":''} 
            <div className='OffshoreRestrictionProcessingBelowSentence'>
                    {show===true?'A feature to fetch offshore restriction information from LHC contacts':''}
                </div>
          </div>
          <br></br>
          {show===true?
          <div>
          <div className='innerCircle2 UploadContentDivBlueCircle'>
          <img src={UploadContractImage} alt='UploadContractImage' onClick={handleClickOpen} className='height icon'/>
          </div>
          <div className='UploadContent'  onClick={handleClickOpen}>Upload Contract</div>
          <div className='innerCircle2 AcessResultDivBlueCircle'>
          <img src={AccessResultsOffshore} alt='AccessResultsOffshore' className='height icon'onClick={()=>(navigate('/Archive'))}/>
          </div>
          <div className='AccessResults' onClick={()=>(navigate('/Archive'))}>Access Offshore Results</div>
          {/* <IoMailOutline size={40} color='#002b80' onClick={handleInviteClickOpen} title='send invite' className='invite'/> */}
          </div>
          :''}
  
<UploadFile open={open} handleClose={handleClose} uniqueId={uniqueId}/>
{/* <Invite open={inviteOpen} handleClose={handleInviteClose}/> */}
<SnackBar ref={alertRef}/>     
{loader}

   </div>

  )
}

export default SearchPage;


