import './App.css';
import {useState,useEffect} from 'react';
import { Routes,Route ,Navigate} from 'react-router-dom';
import LandingPage from './Pages/LandingPage';
import SearchPage from './Pages/SearchPage';
import ClientSummary from './Pages/ClientSummary';
import {
  useIsAuthenticated,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ssoRequest } from "./config";
import Unauthorize from './Pages/Unauthorize';
import { UserContext} from './Hook/UserContext';
import Login from './Pages/Login';
import SearchQueryPage from './Pages/SearchQueryPage';
import { useSelector } from 'react-redux';
import { getCookie } from "../src/Endpoints/LoginActions";




 

function App() {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // const userInfoSessionStorage= JSON.parse(sessionStorage.getItem("userInfo"));
  // console.log("Json.parse of session storage info is,",userInfoSessionStorage);
  const userInfoReduxPersist = useSelector((state) => {  //get data from the main store
    return state.UserInfoSlice;
})

// useEffect(()=>{
//   console.log("userInfo is.....",userInfoReduxPersist);
// },[])

  let userRole = 0;
  // console.log(accounts);
  if(accounts.length >0){
    sessionStorage.setItem("userEmail",accounts[0].username)
  }
  if(accounts.length >0 && accounts && accounts[0].username === "deeksha.thareja@optum.com"){
    userRole = 1;
  }
  else if (accounts.length > 0 && accounts[0].idTokenClaims) {
    let claims = accounts[0].idTokenClaims.groups;
    if (claims.includes(process.env.REACT_APP_SECURE_GROUP_ADMIN)) {
      userRole = 1;
    }  
  }
  const [code,setCode] = useState("");
  const [userInfoSessionStorage,setUserInfoSessionStorage]=useState(JSON.parse(sessionStorage.getItem("userInfo")));


  return (
    <UserContext.Provider value={{userRole,code,setCode,setUserInfoSessionStorage}}>
        <AuthenticatedTemplate>
          {userRole > 0 ? (
            <Routes>
                 <Route exact path="/" element={<SearchPage />} />  
                 <Route exact path="/Archive" element={<LandingPage />} />   
                 <Route exact path="/ClientSummary/:payer_name" element={<ClientSummary />} />  
                 <Route exact path="search/:text" element={<SearchQueryPage/>} />

            </Routes>
          ) : (
            // <Routes>
            // <Route exact path="/" element={<SearchPage />} />  
            // <Route exact path="/Archive" element={<LandingPage />} />   
            // <Route exact path="/ClientSummary/:payer_name" element={<ClientSummary />} />  
            // <Route exact path="search/:text" element={<SearchQueryPage/>} />

      //  </Routes>
            <Routes>
              <Route path="/" exact element={<Navigate to="/unauthorize" />}></Route>
              <Route exact path="/unauthorize" element={<Unauthorize />} />
            </Routes>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
        {
           // (code===""&&userInfoReduxPersist?.userInfo==="")?
           (code!==""&&userInfoSessionStorage!==null)?
            (<Routes>
              <Route exact path="/" element={<SearchPage />} />  
              <Route exact path="/Archive" element={<LandingPage />} />   
              <Route exact path="/ClientSummary/:payer_name" element={<ClientSummary />} />  
              <Route exact path="search/:text" element={<SearchQueryPage/>} />
              </Routes>
         
            ):
            (
              <Routes>
              <Route path="/" exact element={<Login />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="*" element={<Login />}></Route>
              </Routes>

            )

            }
        </UnauthenticatedTemplate>
    </UserContext.Provider>
  );
}

export default App;
