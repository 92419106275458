import axios from "axios";
import { saveUserInfo } from '../Store/slices/UserInfoSlice';


export const getEmail=()=>{
    if(sessionStorage.getItem("userEmail"))
    {
        return sessionStorage.getItem("userEmail");
    }

    else if(sessionStorage.getItem("userInfo")){
        try{
            return JSON.parse(sessionStorage.getItem("userInfo"))?.email || null;

        }
        catch(error)
        {
            return null;
        }
    }

    return null;

}
export const apiTokenGetter = async() => {

    const user_id = getEmail();

    if(getCookie('apiToken')&&(getCookie('apiToken')!==null||getCookie('apiToken')!=="undefined")){
        console.log("api token in cookies is....",getCookie('apiToken'));
        return getCookie('apiToken');
    }

    const key = 'a2962f0b-5e97-4e44-985c-d79e7a46eaa8' + '_' + user_id;

    const generateHash = async (input) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(input);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    };
    const encryptedKeySecret= await generateHash(key) ;

   console.log("encrypted key secret is...",encryptedKeySecret);

    const url = "https://cz-lhc-backend-hhf9duhfawbsfzhk.a03.azurefd.net/apis/get_token"

    const input_json = {
        "secret": encryptedKeySecret,
        "user_id": user_id
    }


    const header = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    const responseToken="";

    try{
    const response = await axios.post(url, input_json, header)
    

        console.log("response.data.result is",response?.data?.Result)
        return response?.data?.Result;

    }

    catch(error){
        console.log("error is",error);
    }


       

}

export const TokenGetter = (code, callback) => {

    const url = process.env.REACT_APP_OHID_WRAPPER_TOKEN_URL || '';

    const header = {
        headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" }
    }


    axios.post(url, data(code), header)
        .then((response) => {
            callback(response.data.access_token);
            return response.data.access_token;
        })
        .catch((error) => {
            return error;
        })
}

const data = (auth_code) => {
    return {
        'code': auth_code,
        'client_id': process.env.REACT_APP_OHID_TOKEN_CLIENT_ID,
        'client_secret': process.env.REACT_APP_OHID_TOKEN_CLIENT_SECRET,
        'grant_type': process.env.REACT_APP_OHID_TOKEN_GRANT_TYPE,
        'redirect_uri': process.env.REACT_APP_OHID_REDIRECT_URI,
    }
}

export const GetUserInfoCode = async (code, dispatch, callback) => {

    console.log("entered USerInfo Code")
    if (!code) return;


    const headers = {
        headers: { "Content-Type": "application/json" }
    }


   

    const url = process.env.REACT_APP_API_URI + "user_info" || '';

    const obj = {
        "request_id": "1234",
        "auth_code": code,
        
    }


    axios.post(url, obj, headers)
        .then((response) => {
            console.log("userInfo response in backend api call is.....,", response)
            //dispatch(saveUserInfo(response.data));
            //setCookie("userInfo",JSON.stringify(response.data),0);
            if (response.hasOwnProperty('data') && response?.data.hasOwnProperty('Result')) {
                if (response?.data?.Result !== null) {
                    console.log("userInfo stored in session");
                    sessionStorage.setItem("userInfo", JSON.stringify(response?.data?.Result));
                    
                }

                callback("success");
            }
           
            //set the userInfo object returned from api here and also see what api returns data successfully, in case of any error ,set the 
            //corresponding error value in app.js string
           
            return response;
        })
        .catch((error) => {
            console.log("reached userInfo code catch")
            return error;

        });









}

export const GetUserInfo = async (accessToken, dispatch, callback) => {


    if (!accessToken) return;



    const url = process.env.REACT_APP_OHID_WRAPPER_USERINFO_URL || '';

    axios.get(`${url}?access_token=${accessToken}`)
        .then((response) => {
            console.log("userInfo response is.....,", response)
            dispatch(saveUserInfo(response.data));
            //setCookie("userInfo",JSON.stringify(response.data),0);

            callback(JSON.stringify(response.data));
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}

export const setCookieToken = (name, value, expirationMinutes) => {
    
    const expirationDate = new Date(new Date().getTime() + expirationMinutes * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
};

export const setCookie = (name, value, minutes) => {
    // set cookies local to use in all app
    // console.log('Set coocies', name, value, minutes);
    let expires = '';
    if (minutes) {
        const date = new Date();
        date.setMinutes(date.getMinutes() + minutes);
        //expires = `; expires=${date.toUTCString()}`;
        //${expires}
    }
    // console.log('************', (document.cookie = `${name}=${value || ''}${expires}; path=/`));
    document.cookie = `${name}=${value || ''}; path=/`;
    // console.log(' after Set coocies', document.cookie);
};

export const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

