import React from 'react';
import { useState, useEffect,useMemo } from 'react';
// import ExpandText from "../Component/ExpandText";
import { Box,Typography,Checkbox, TextField,} from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import ExpandText from "../Components/ExpandText";
import { FaRegFile } from "react-icons/fa";
import { FaCheckCircle,FaTimesCircle } from 'react-icons/fa';
import ContractViewImage from "./ContractViewImage";
import ContractIcon from '../Img/ICON_Contract.png';








const ClientSummaryTable=(props)=> {

  const [data, setData] = useState(props.data.para_data);
  const [showTable, setShowTable] = useState(false);
  const [showContractImage, setShowContractImage] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [agreementNumber, setAgreementNumber] = useState("");
  const [pageNumber, setPageNumber] = useState("");
  const contract_name=props.contract_name;
  const agreement_id=props.agreement_id;
  
  const handleContractViewImage = (id, agreementNo, pageNo) => {
    try{
    // console.log("AgreementNo", agreementNo);
    // console.log("page", pageNo);
    setShowContractImage(true);
    setRowIndex(id);
    setAgreementNumber(agreementNo);
    setPageNumber(pageNo);
    }

    catch(error)
    {
    //   AppInsight.trackException({error});
    //   AppInsight.trackEvent({name:'error-handleContractViewImage-searchResult=>frontend',properties:{errorMessage:error.message}});
    //   setShowSnackbar(true);
    }

  }

  const closeContractViewImage = () => {
    setShowContractImage(false);
  }

   useEffect(() => {
    try{
    setShowTable(props.tableValue);
    }
    catch(error){}
  }, [props.tableValue])


  
  useEffect(() => {
    try{
    setData(props.data.para_data);
    }
    catch(error){}
  }, [props.data.para_data]);



  let columns = useMemo(
    () => [
      {
        accessorKey: 'paragraph', //normal accessorKey
        muiTableHeadCellProps: { sx: { color: "#002677" } ,align: 'center'}, //custom props
        header: 'Paragraph',
            Cell: ({ renderedCellValue, row }) => (
            <div >
             <ExpandText text={row.original.paragraph} maxLength={100} />
  
            </div>
          ),
        // enableClickToCopy: true,
        minSize: 30,
        maxSize: 250,
        size: 250,
        enableColumnFilter: true,
        enableEditing: false,
        enableSorting:false,

      },

      {
        accessorKey: 'page_no',
        muiTableHeadCellProps: { sx: { color: "#002677" },align: 'center' }, //custom props
        header: 'Page No.',
        muiTableBodyCellProps: {
          align: 'center',
        },
        Header: ({ column }) => {
          let tempHeader = column.columnDef.header.split(" ");
          return <div>
            {tempHeader[0]}<br />{tempHeader[1]}
          </div>
        },
        minSize: 30,
        maxSize: 30,
        size: 30,
        enableEditing: false,
      },
      {
        accessorKey: 'offshore_flag', //normal accessorKey
        header: 'Offshore Flag',
        enableSorting:false,
        Header: ({ column }) => {
          let tempHeader = column.columnDef.header.split(" ");
          return <div>
            {tempHeader[0]}<br />{tempHeader[1]}
          </div>
        },
        minSize: 30,
        maxSize: 30,
        size: 30,
        enableSorting:false,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => <div style={{color:'#cc0000'}}>{renderedCellValue}</div>, //optional custom cell render
        muiTableHeadCellProps: { sx: { color: "#002677" },align: 'center' }, //custom props
      },
      {
        accessorKey: 'restrictiontype',
        header: 'Restriction Type',
        enableSorting:false,
        Header: ({ column }) => {
          let tempHeader = column.columnDef.header.split(" ");
          return <div>
            {tempHeader[0]}<br />{tempHeader[1]}
          </div>
        },
        minSize: 30,
        maxSize: 150,
        size: 150,
        enableEditing: false,
        muiTableHeadCellProps: { sx: { color: "#002677" } ,align: 'center'}, //custom props
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'restrictioncontext',
        header: 'Restriction Context',
        enableSorting:false,
        Header: ({ column }) => {
          let tempHeader = column.columnDef.header.split(" ");
          return <div>
            {tempHeader[0]}<br />{tempHeader[1]}
          </div>
        },
        minSize: 30,
        maxSize: 200,
        size: 200,
        enableSorting:false,
        Cell: ({ renderedCellValue, row }) => (
          <div >
           <ExpandText text={row.original.restrictioncontext} maxLength={50} />

          </div>
        ),
        enableEditing: false,
        muiTableHeadCellProps: { sx: { color: "#002677" } ,align: 'center'}, //custom props
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'pwa',
        header: 'Prior Written Approval',
        enableSorting:false,
        minSize: 150,
        maxSize: 150,
        size: 150,
        enableEditing: false,
        muiTableHeadCellProps: { sx: { color: "#002677" },align: 'center' }, //custom props
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: 'Contract View',
        enableSorting:false,
        Header: ({ column }) => {
          let tempHeader = column.columnDef.header.split(" ");
          return <div>
            {tempHeader[0]}<br />{tempHeader[1]}
          </div>
        },
        minSize: 30,
        maxSize: 30,
        size: 30,
        enableEditing: false,
        muiTableHeadCellProps: { sx: { color: "#002677" },align: 'center' }, //custom props
        Cell: ({ row }) => (
          <div style={{ paddingLeft: '15px' }}>
            <img src={ContractIcon} alt={'contractImg'} style={{ cursor: 'pointer' }} onClick={() => handleContractViewImage(row.index, agreement_id, row.original.page_no)} />
          </div>
        ),       
      },
      {
        accessorKey:'feedback',
        header:'',
        enableSorting:false,
        Cell:({row})=>{
          const rowIndex=row.index;
        return(
            <div>
             <TextField
             placeholder='Feedback'
             sx={{width:'200px'}}
             size="small"
             value={row.original.feedback}
             onChange={(e)=>props.feedbackHandleChange(agreement_id,rowIndex,e.target.value)}
             />

            </div>)
        }
      },
      {
        accessorKey:'review_status',
        minSize: 100,
        maxSize: 100,
        size: 100,
        enableSorting:false,

        Cell:({row})=>{
          const rowIndex=row.index;
          return(
          <div >
          <FaCheckCircle 
          size={20}
          style={{cursor:'pointer',
          color:row.original.review_status==='Accepted'?'green':'gray',
          marginRight:'8px'}}
          onClick={()=>(props.handleIconClick(agreement_id,rowIndex,'Accepted'))}
          />
          <FaTimesCircle
          size={20}
          style={{cursor:'pointer',
           color:row.original.review_status==='Denied'?'red':'gray'
          
        }}
        onClick={()=>(props.handleIconClick(agreement_id,rowIndex,'Denied'))}
          />
          </div>)
        }, 
      }
      
    ],
    [],
  );


  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableGrouping: true,
    groupedColumnMode: 'remove',
    enableColumnActions:false,
    enableColumnDragging:false,
    // initialState: {
    //   sorting: [
    //     { id: 'page_no', desc: false }, //sort by state in ascending order by default
    //   ],
    // },
    initialState: {
      sorting: [
        {
          id: 'page_no', //sort by age by default on page load
          desc: false,
        }
      ]
    },
    muiTableBodyCellProps: ({ column }) => ({
      //conditionally style pinned columns
      sx: {
        fontSize: '12px',
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '10px',
          sm: '11px',
          md: '11px',
          lg: '12px',
          xl: '13px',
        },
        padding: '5px',

      },
    },
    enableSorting:true,
    enablePagination:false,
    enableBottomToolbar:false,
    enableDensityToggle:false,
    enableFullScreenToggle:false,
    enableColumnFilters:false,
    enableGlobalFilter:false,
    enableHiding:false,
    muiTopToolbarProps:{sx:{backgroundColor: "white"}},    
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
      sx={{
        display: 'flex',
        paddingLeft: '8px',
        paddingRight: '8px',
        flexWrap: 'wrap',
        alignItems: "center",
        width: '100%',
      }}
    >

      <div className="row col-lg-12">
        <div className="col-lg-9">
          <Typography style={{ color: "#002b80", fontSize: '13px', fontWeight: 'bold' }}>Contract:{contract_name}</Typography>
        </div>
        <div className="col-lg-3">
          {/* <div style={{ fontSize: '13px', fontWeight: 'bold', color: '#002b80',float:'left',paddingLeft:'20%'}} >
            <Checkbox
              checked={props.data.selectAll.Accepted}
              onChange={() => props.handleCheckboxClick(agreement_id, 'Accepted')}
            />
            Accept All
          </div>
          <div style={{ fontSize: '13px', fontWeight: 'bold', color: '#002b80',float:'right' }}>
            <Checkbox
              checked={props.data.selectAll.Denied}
              onChange={() => props.handleCheckboxClick(agreement_id, 'Denied')}
            />
            Deny All
          </div> */}
        </div>
      </div>

    </Box>
    ),

  });

  return (
    <div>
         {showTable &&
        <MaterialReactTable table={table} />
      }
       {showContractImage &&
        <ContractViewImage close={closeContractViewImage} rowIndex={rowIndex} agreement_Number={agreementNumber} page_Number={pageNumber} />
      }
    </div>
  )
}

export default ClientSummaryTable;
