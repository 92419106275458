import React, { useEffect,useState,useRef} from 'react'
import LandingPageTable from '../Components/LandingPageTable';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Sidebar from '../Components/Sidebar';
import axios from 'axios';
import useLoader from "../Hook/useLoader";
import SnackBar from '../Components/SnackBar';
import headerImg from '../Img/HEADER.png';
import { TokenGetter, GetUserInfo,getCookie,setCookie,getEmail,setCookieToken,apiTokenGetter } from "../Endpoints/LoginActions";


// import

const LandingPage=()=> {
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();
  const alertRef=useRef();
  const [reviewedContracts,setReviewedContracts]=useState('');
  const [contracts,setContracts]=useState('');
  const [clients,setClients]=useState('');




const apiCallDummy=async()=>{
  try {
    showLoader();

    const header = {
      headers: {
          "Content-Type": "application/json",
      }
  }
  

    const apiToken = await apiTokenGetter();
    const email = getEmail();
  const input_json={
    "token":apiToken,
    "user_id":email
  };

  const response= await axios.post(process.env.REACT_APP_API_URI+"processed_contracts", input_json, header);




    // const response = await axios.post(
    //   process.env.REACT_APP_API_URI+"processed_contracts"
    // );
    // console.log('response on landing page',response)
    // console.log('type',typeof(response.data))
    // const val=response.data.Results;
    // const val1=new Set();
    // const val3={}
    // const val2=new Set();
    //checking duplicate data in record
    // val.forEach(item=>{
    //   const{Agreement_ID,Contract_Name}=item;
    //   if(val1.has(Agreement_ID)){
    //     if(!val3[Agreement_ID]){
    //       val3[Agreement_ID]=[];
    //     }
    //     val3[Agreement_ID].push(Contract_Name);}
    //     else{
    //       val1.add(Agreement_ID);
    //     }
    //   }
    // )
    // console.log("val2",val3);

    
 

    const distinctContracts=new Set(response.data.Results.map(item=>item.Agreement_ID)).size;
    const distinctClients=new Set(response.data.Results.map(item=>item.Payer_Name)).size;
    if(response.status===200){
      setShowTable(true);
      hideLoader();
    }
    setData(response.data.Results);
    setReviewedContracts(response.data.Reviewed_contracts);
    setContracts(distinctContracts);
    setClients(distinctClients);
  } catch (error) {
    alertRef.current.showSnackbar();
    hideLoader() 

  }
}

  useEffect(()=>{
    apiCallDummy()
  },[]);

  return (
    <div>
    <div className="parent">
  <div className="child1" >
    <img src={headerImg} style={{width:'100%'}}/>
    <div className='landingPage700 '>{clients}
      <div className='clients'>Clients</div>
  </div>
  <div className='landingPage1300 '>
    {contracts}<div className='contracts'>Contracts</div>
  </div>
  <div className='landingPage500 '>
    &nbsp;{reviewedContracts}<div className='reviewedContracts'>Reviewed Contracts</div>
  </div>
  </div>
  <div className="main">
    <div className="child">    <Sidebar/>
</div>
    <div className="child3 content">
      <div className="width101" >

        {loader}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <LandingPageTable
                                data={data}
                                tableValue={showTable}
                            />{" "}



</LocalizationProvider>
</div>

    </div>  
  </div>
  <SnackBar ref={alertRef}/>     
</div>
   </div>
  )
}

export default LandingPage;